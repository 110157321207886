import { Checkbox, FormControlLabel, Box } from '@mui/material';
import type { Column } from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react';

type CheckboxFilterProps<T> = {
  column: Column<T>,
  label: string,
  onlyFilterOnTrue?: boolean,
  filterValueOnCheck?: 'null' | '' | '0',
};

const CheckboxFilter = <T, >(props: CheckboxFilterProps<T>) => {
  const {
    column, label, onlyFilterOnTrue, filterValueOnCheck,
  } = props;
  const filterValue = column.getFilterValue();
  const { setFilterValue } = column;

  const isChecked = useMemo(() => {
    if (filterValueOnCheck !== undefined) {
      if (filterValue === undefined) return false;
      switch (filterValueOnCheck) {
        case 'null':
          return typeof filterValue === null || filterValue === null;
        case '0':
          return filterValue === 0;
        case '':
          return filterValue === '';
        default:
          return Boolean(filterValue ?? false);
      }
    }
    return Boolean(filterValue ?? false);
  }, [filterValue, filterValueOnCheck]);

  const onChange = useCallback((checked: boolean) => {
    if (filterValueOnCheck !== undefined) {
      switch (filterValueOnCheck) {
        case 'null':
          setFilterValue(checked ? null : undefined);
          break;
        case '0':
          setFilterValue(checked ? 0 : undefined);
          break;
        case '':
          setFilterValue(checked ? '' : undefined);
          break;
        default:
          setFilterValue(checked);
          break;
      }
    } else if (onlyFilterOnTrue && !checked) {
      setFilterValue(undefined);
    } else {
      setFilterValue(checked);
    }
  }, [onlyFilterOnTrue, filterValueOnCheck, setFilterValue]);

  return (
    <Box>
      <FormControlLabel
        control={(
          <Checkbox
            checked={isChecked}
            onChange={(e) => onChange(e.target.checked)}
          />
      )}
        label={label}
      />
    </Box>
  );
};

export default CheckboxFilter;
