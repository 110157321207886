import type { Row } from '@tanstack/react-table';

export const nullEqualsFilter = <T,>(row: Row<T>, columnId: string, filterValue: string | null | number): boolean => {
  const val = row.getValue(columnId);

  if (typeof filterValue === null) {
    if (typeof val === null) return true;
  }

  return val === filterValue;
};

export default nullEqualsFilter;
